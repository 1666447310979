#loader {
  overflow: visible;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #3498db;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 500ms;
  z-index: 101;
  pointer-events: none;

  &[uiState="hidden"] {
    opacity: 0;
  }

  &::after {
    content: "";
    height: 240px;
    width: 240px;
    margin: 0 auto;
    position: relative;
    animation: rotation 0.6s infinite linear;
    border-left: 48px solid rgba(0, 174, 239, 0.15);
    border-right: 48px solid rgba(0, 174, 239, 0.15);
    border-bottom: 48px solid rgba(0, 174, 239, 0.15);
    border-top: 48px solid rgba(0, 174, 239, 0.8);
    border-radius: 100%;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
