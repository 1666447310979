nav {
  $start: $scene_02_begin - 500;
  $color1-light: rgba(52, 152, 219, 0.99);
  $color1-dark: rgba(51, 127, 177, 0.99);
  $color2-light: rgba(68, 198, 80, 0.99);
  $color2-dark: rgba(69, 155, 69, 0.99);
  $color3-light: rgba(44, 62, 80, 0.99);
  $color3-dark: rgba(21, 39, 53, 0.99);
  $color4-light: rgba(231, 76, 60, 0.99);
  $color4-dark: rgba(190, 61, 47, 0.99);
  $color5-light: rgba(43, 172, 181, 0.99);
  $color5-dark: rgba(34, 144, 152, 0.99);

  #bgmenu {
    opacity: 0;
    pointer-events: none;
  }

  #sc-menu1 {
    cursor: default;
  }

  #sc-menu3 {
    cursor: default;
  }

  #sc-menu5 {
    cursor: default;
  }

  #sc-menu6 {
    cursor: default;
  }

  #bar {
    -st-animation-name: menubar;
  }

  @-st-keyframes menubar {
    #{$scene_01_begin} {
      transform: scale(0, 1);
      fill: $color1-light;
    }

    #{$scene_02_begin - 300} {
      transform: scale(0.34, 1);
      fill: $color1-light;
    }

    #{$scene_03_begin - 280} {
      transform: scale(0.35, 1);
      fill: $color2-light;
    }

    #{$scene_03_begin} {
      transform: scale(0.38, 1);
      fill: $color2-light;
    }

    #{$scene_04_end - 300} {
      transform: scale(0.58, 1);
      fill: $color2-light;
    }

    #{$scene_04_end - 280} {
      transform: scale(0.59, 1);
      fill: $color4-light;
    }

    #{$scene_05_begin} {
      transform: scale(0.62, 1);
      fill: $color4-light;
    }

    #{$scene_05_end - 300} {
      transform: scale(0.72, 1);
      fill: $color4-light;
    }

    #{$scene_05_end - 280} {
      transform: scale(0.73, 1);
      fill: $color5-light;
    }

    #{$scene_06_begin} {
      transform: scale(0.75, 1);
      fill: $color5-light;
    }

    #{$scene_06_end - 300} {
      transform: scale(0.9, 1);
      fill: $color5-light;
    }

    #{$scene_06_end - 280} {
      transform: scale(1.15, 1);
      fill: $color5-light;
    }

    #{$scene_06_end} {
      transform: scale(1.16, 1);
      fill: $color5-light;
    }
  }

  #menu {
    -st-animation-name: menu;
  }

  @-st-keyframes menu {
    #{$scene_01_begin + 60} {
      transform: translate(0px, 200px);
    }

    #{$scene_01_begin + 100} {
      transform: translate(0px, 0px);
    }
  }

  #barleft {
    -st-animation-name: barleft;
  }

  @-st-keyframes barleft {
    #{$scene_01_begin} {
      fill: $color1-light;
    }

    #{$scene_02_begin - 300} {
      fill: $color1-light;
    }

    #{$scene_03_begin - 280} {
      fill: $color2-light;
    }

    #{$scene_03_end - 300} {
      fill: $color2-light;
    }

    #{$scene_04_end - 300} {
      fill: $color2-light;
    }

    #{$scene_04_end - 280} {
      fill: $color4-light;
    }

    #{$scene_05_end - 300} {
      fill: $color4-light;
    }

    #{$scene_05_end - 280} {
      fill: $color5-light;
    }

    #{$scene_06_end - 300} {
      fill: $color5-light;
    }

    #{$scene_06_end - 280} {
      fill: $color5-light;
    }

    #{$scene_06_end} {
      fill: $color5-light;
    }
  }

  #barcenter {
    -st-animation-name: barcenter;
  }

  @-st-keyframes barcenter {
    #{$scene_01_begin} {
      fill: $color1-dark;
    }

    #{$scene_02_begin - 300} {
      fill: $color1-dark;
    }

    #{$scene_03_begin - 280} {
      fill: $color2-dark;
    }

    #{$scene_03_end - 300} {
      fill: $color2-dark;
    }

    #{$scene_04_end - 300} {
      fill: $color2-dark;
    }

    #{$scene_04_end - 280} {
      fill: $color4-dark;
    }

    #{$scene_05_end - 300} {
      fill: $color4-dark;
    }

    #{$scene_05_end - 280} {
      fill: $color5-dark;
    }

    #{$scene_06_end - 300} {
      fill: $color5-light;
    }

    #{$scene_06_end - 280} {
      fill: $color5-light;
    }

    #{$scene_06_end} {
      fill: $color5-light;
    }
  }

  #barright {
    -st-animation-name: barright;
  }

  @-st-keyframes barright {
    #{$scene_01_begin} {
      fill: $color1-dark;
    }

    #{$scene_03_begin - 300} {
      fill: $color1-dark;
    }

    #{$scene_03_begin - 280} {
      fill: $color2-dark;
    }

    #{$scene_03_end - 300} {
      fill: $color2-dark;
    }

    #{$scene_04_end - 300} {
      fill: $color2-dark;
    }

    #{$scene_04_end - 280} {
      fill: $color4-dark;
    }

    #{$scene_05_end - 300} {
      fill: $color4-dark;
    }

    #{$scene_05_end - 280} {
      fill: $color5-dark;
    }

    #{$scene_06_end - 300} {
      fill: $color5-dark;
    }

    #{$scene_06_end - 280} {
      fill: $color5-dark;
    }

    #{$scene_06_end} {
      fill: $color5-dark;
    }
  }
}
