@import 'normalize';
@import 'helpers';
@import 'scrollytelly';
@import 'print-styles';

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #151d29;

  &[data-display="none"] {
    background: #3498db;
  }
}

#resume {
  width: 90%;
  margin: 0 auto;
  max-width: 1026px;

  [data-display="none"] & {
    display: none;
  }
}

#sidebar {
  border-top: 15px solid #388ac1;
  background: #3498db;

  a {
    strong {
      font-weight: normal;
    }

    &:link,
    &:visited {
      color: white;
      font-weight: 100;
    }

    &:hover,
    &:focus {
      color: #151d29;
    }
  }

  #thislink {
    display: none;
  }

  #printfriendly {
    font-weight: 100;
    font-size: 2rem;
  }
}

.portrait-mode {
  display: none;
}

@media all and (orientation: portrait) {
  .portrait-mode {
    display: block;
    text-align: center;
    background: #3498db;
    font-size: 1.5em;
    color: white;
    padding: 1.5em;
  }
}

.main {
  padding: 0;

  article {
    margin-bottom: 64px;

    h1 {
      font-size: 2em;
    }
  }

  aside {
    color: white;
    padding: 0 5% 10px;
  }

  p {
    font-weight: 400;
  }
}

.main h1,
.main h2,
.main h3 {
  font-weight: 300;
}

.print_only {
  display: none;
}

/* ==========================================================================

========================================================================== */

.list {
  time {
    float: right;
  }

  section {
    border-bottom: 1px solid #151d29;
    font-size: small;
    margin-left: 1em;

    &:last-child {
      border: none;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0.3em;
    clear: left;
  }

  h3 {
    float: left;
    margin-bottom: 0;
    margin-top: 0.3em;
  }
}

#reopen {
  color: #151d29;

  &:hover {
    color: white;
  }
}

.skill-set {
  //display: flex;
  font-size: small;
  margin-bottom: 16px;
  overflow: auto;

  ul {
    float: left;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      &::before {
        content: "-";
        color: grey;
        margin: 0 16px;
      }
    }
  }
}

.horizontal-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: small;
  font-style: italic;

  li {
    &:last-child,
    &:nth-child(1) {
      &::after {
        content: '';
        margin: 0;
      }
    }

    &:nth-child(1) {
      &::after {
        margin-left: 4px;
      }
    }

    &::after {
      content: "-";
      color: grey;
      margin: 0 2px;
    }
  }
}

@media only screen and (min-width: 768px) {
  #sidebar {
    box-shadow: 0 5px 10px #aaa;
    float: right;
    width: 28%;
  }

  #resumeData {
    float: left;
    width: 57%;
  }
}
