#scene2 {
  .svg {
    width: 100%;
    height: 100%;
  }

  $start: $scene_02_begin;

  #wire2 {
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-dasharray: 6000;
    stroke-dashoffset: 0;
    -st-animation-name: wire2;
  }

  @-st-keyframes wire2 {
    #{$start - 170} {
      stroke-dashoffset: 6000;
    }

    //#{$start + 1200} {
    //  stroke-dashoffset: 2100;
    //}

    #{$start + 2320} {
      stroke-dashoffset: 0;
    }
  }

  #text1 {
    -st-animation-name: text1;
  }

  @-st-keyframes text1 {
    #{$start - 100} {
      opacity: 0;
    }

    #{$start - 50} {
      opacity: 1;
    }
  }

  #text2 {
    -st-animation-name: text2;
  }

  @-st-keyframes text2 {

    #{$start - 50} {
      opacity: 0;
    }

    #{$start } {
      opacity: 1;
    }

    #{$start + 150} {
      opacity: 1;
    }

    #{$start + 200} {
      opacity: 0;
    }
  }

  #text3 {
    -st-animation-name: text3;
  }

  @-st-keyframes text3 {

    #{$start + 150} {
      opacity: 0;
    }

    #{$start + 250} {
      opacity: 1;
      //fill: rgb(245, 236, 217);
    }
  }

  #london {
    -st-animation-name: london;
  }

  @-st-keyframes london {

    #{$start + 200} {
      transform: translate(-250px, 600px);
    }

    #{$start + 400} {
      transform: translate(0px, 0px);
    }
  }

  #bus1 {
    -st-animation-name: bus1;
  }

  @-st-keyframes bus1 {

    #{$start + 300} {
      transform: translate(400px, 275px) scale(0.12);
    }

    #{$start + 1200} {
      transform: translate(1000px, 275px) scale(0.12);
    }
  }

  #bus2 {
    -st-animation-name: bus2;
  }

  @-st-keyframes bus2 {

    #{$start + 300} {
      transform: translate(0, 0);
    }

    #{$start + 1200} {
      transform: translate(300px, 0px);
    }
  }

  #planebig {
    -st-animation-name: planebig;
  }

  @-st-keyframes planebig {

    #{$start + 100} {
      transform: translate(50px, 10px);
    }

    #{$start + 400} {
      transform: translate(3000px, 200px);
    }

    #{$start + 600} {
      transform: translate(8000px, 400px);
    }
  }

  #clouds {
    -st-animation-name: clouds;
  }

  @-st-keyframes clouds {

    #{$start - 150} {
      transform: translate(-100px, 0px);
      opacity: 0;
    }

    #{$start - 100} {
      transform: translate(-50px, 0);
      opacity: 1;
    }

    #{$start + 700} {
      transform: translate(0px, 0);
      opacity: 1;
    }

    #{$start + 1100} {
      opacity: 1;
      transform: translate(200px, 0);
    }
  }

  #plane {
    -st-animation-name: plane;
  }

  @-st-keyframes plane {
    #{$start - 150} {
      transform: translate(-2600px, 0px);
      opacity: 1;
    }

    #{$start + 400} {
      transform: translate(2600px, -600px);
      opacity: 1;
    }
  }

  #mountains {
    -st-animation-name: mountains;
  }

  @-st-keyframes mountains {
    #{$start - 50} {
      transform: translate(-200px, 0px);
      opacity: 0;
    }

    #{$start} {
      transform: translate(0px, 0px);
      opacity: 1;
    }

    #{$start + 200} {
      transform: translate(50px, 0px);
      opacity: 1;
    }

    #{$start + 250} {
      transform: translate(-200px, 0px);
      opacity: 0;
    }
  }

  #torre {
    -st-animation-name: torre;
  }

  @-st-keyframes torre {
    #{$start - 100} {
      transform: translate(-1200px, 0px) scale(1);
      opacity: 1;
    }

    #{$start - 50} {
      transform: translate(-10px, 10px) scale(0.7);
      opacity: 1;
    }

    #{$start + 250} {
      transform: translate(-10px, 10px) scale(0.7);
      opacity: 1;
    }

    #{$start + 300} {
      transform: translate(-300px, 0px) scale(1);
      opacity: 0;
    }
  }
}
