/* ---------------------------------------------------------
Print styles
--------------------------------------------------------- */

@media print {
  * {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: transparent !important;
  }

  html {
    background-color: #fff;
    height: auto !important;
  }

  #sidebar {
    border: none !important;

    h3 {
      display: none;
    }

    #thislink {
      display: block;
    }
  }

  header p {
    margin: 0;
    padding: 0;
  }

  .print_only {
    display: block !important;
  }

  .no-print {
    display: none !important;
  }

  figure,
  #printit,
  #reopen,
  #printfriendly {
    display: none !important;
    content: "";
  }

  body {
    display: block;
    font-size: 12px;
    overflow: hidden;
    height: auto !important;
  }

  /* Show link destinations in brackets after the link text */

  a[href]::after {
    content: ": " attr(href) " ";
  }

  a[href] {
    text-decoration: none;
    color: #06c;
    border: none;
  }

  h3 {
    font-weight: bold !important;

    a {
      font-weight: bold !important;
    }
  }

  /* Don't show link destinations for JavaScript or internal links */

  a[href^="javascript:"]::after,
  a[href^="#"]::after,
  a.plain[href]::after {
    content: "";
  }

  /* Show abbr title value in brackets after the text */

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  .main-container > div > aside > p {
    //white-space: pre;
    margin: 0;
    font-size: 14px;
  }

  .main-container > div > aside section p {
    margin: 0;
    padding: 0;
  }

  .main-container > div > aside a {
    font-size: 14px;
  }

  .main-container > div > aside p::before {
    content: '';
  }

  .main aside {
    border: 0;
    padding: 0;
  }
}
