@font-face {
  font-family: "Simple Chalk Regular";
  font-style: normal;
  font-weight: normal;
  src: url('./scene6/Simple Chalk.woff') format('woff');
}

#scene6 {

  $start: $scene_06_begin;
  $photostart: $start + 1500;

  //background-color: #2BACB5;

  .scene-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: none;
    pointer-events: none;
    -st-animation-name: a_scene6_content;
  }

  @-st-keyframes a_scene6_content {

    #{$start - 300} {
      display: block;
      opacity: 0;
    }

    #{$start + 0} {
      opacity: 1;
    }
  }

  #skills_container {
    -st-animation-name: a_scene6_skillscontainer;
  }

  @-st-keyframes a_scene6_skillsContainer {
    #{$start + 1200} {
      opacity: 1;
      display: block;
    }

    #{$start + 1250} {
      opacity: 0.5;
      display: block;
    }

    #{$start + 1260} {
      display: none;
    }
  }

  .word {
    position: absolute;
    opacity: 0;
    width: 100%;
    white-space: nowrap;
    transform-origin: 0 0;
    color: #f5ecd9;
    font-weight: 100;
  }

  .polaroid {
    width: 36vh;
    height: 36vh;
    background: #1e1c18;
    border: 2vh solid #f5f5f5;
    border-bottom: 8vh solid #f5f5f5;
    box-shadow: 0 0 1vh -0.1vh #000;
    overflow: hidden;
  }

  .caption {
    width: 32vh;
    margin-top: -7vh;
    margin-left: 5vh;
    font-family: "Simple Chalk Regular";
    font-size: 3.5vh;
  }

  #photo_container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .photo1 {
    position: absolute;
    left: 0;
    -st-animation-name: a_scene6_photo1;
  }

  @-st-keyframes a_scene6_photo1 {
    #{$photostart} {
      transform: translate(-60vh, 0vh) rotate(0deg);
    }

    #{$photostart + 50} {
      transform: translate(9vh, 5vh) rotate(-5deg);
    }
  }

  .photo2 {
    position: absolute;
    left: 3vh;
    -st-animation-name: a_scene6_photo2;
  }

  @-st-keyframes a_scene6_photo2 {
    #{$photostart + 100} {
      transform: translate(-60vh, 0vh) rotate(-5deg);
    }

    #{$photostart + 150} {
      transform: translate(10vh, 8vh) rotate(0deg);
    }
  }

  .photo3 {
    position: absolute;
    left: 0vh;
    -st-animation-name: a_scene6_photo3;
  }

  @-st-keyframes a_scene6_photo3 {
    #{$photostart + 200} {
      transform: translate(-60vh, 0vh) rotate(0deg);
    }

    #{$photostart + 250} {
      transform: translate(3vh, 12vh) rotate(5deg);
    }
  }

  .photo4 {
    position: absolute;
    left: 4vh;
    -st-animation-name: a_scene6_photo4;
  }

  @-st-keyframes a_scene6_photo4 {
    #{$photostart + 300} {
      transform: translate(-60vh, 0vh) rotate(0deg);
    }

    #{$photostart + 350} {
      transform: translate(8vh, 7vh) rotate(-4deg);
    }
  }

  .photo5 {
    position: absolute;
    left: 4vh;
    -st-animation-name: a_scene6_photo5;
  }

  @-st-keyframes a_scene6_photo5 {
    #{$photostart + 400} {
      transform: translate(-60vh, 0vh) rotate(0deg);
    }

    #{$photostart + 450} {
      transform: translate(11vh, 8vh) rotate(3deg);
    }
  }

  .photo6 {
    position: absolute;
    left: 4vh;
    -st-animation-name: a_scene6_photo6;
  }

  @-st-keyframes a_scene6_photo6 {
    #{$photostart + 500} {
      transform: translate(-60vh, 0vh) rotate(0deg);
    }

    #{$photostart + 550} {
      transform: translate(6vh, 11vh) rotate(2deg);
    }
  }

  .photo7 {
    position: absolute;
    left: 4vh;
    -st-animation-name: a_scene6_photo7;
  }

  @-st-keyframes a_scene6_photo7 {
    #{$photostart + 600} {
      transform: translate(-60vh, 0vh) rotate(0deg);
    }

    #{$photostart + 650} {
      transform: translate(4vh, 16vh) rotate(4deg);
    }
  }

  #email,
  #linkedin,
  #instagram,
  #contactresume,
  #githubsite,
  #medium,
  #twitter {
    cursor: pointer;
    transition-duration: 500ms;
  }

  #email:hover text,
  #linkedin:hover text,
  #instagram:hover text,
  #contactresume:hover text,
  #githubsite:hover text,
  #medium:hover text,
  #twitter:hover text {
    fill: rgba(44, 62, 80, 0.99);
  }

  #sc6title {
    -st-animation-name: a_sc6_title;
  }

  @-st-keyframes a_sc6_title {
    #{$start - 200} {
      opacity: 0;
    }

    #{$start - 150} {
      opacity: 1;
    }

    #{$start + 1200} {
      opacity: 1;
    }

    #{$start + 1250} {
      opacity: 0;
    }
  }

  #graph1 {
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-dasharray: 3000;
    stroke-dashoffset: 0;
    stroke-opacity: 0.7;
    -st-animation-name: graph1;
  }

  @-st-keyframes graph1 {

    #{$start - 150} {
      stroke-dashoffset: 3000;
    }

    #{$start + 600} {
      stroke-dashoffset: 0;
    }
  }

  #graph2 {
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-dasharray: 3000;
    stroke-dashoffset: 0;
    stroke-opacity: 0.7;
    -st-animation-name: graph2;
  }

  @-st-keyframes graph2 {

    #{$start - 50} {
      stroke-dashoffset: 3000;
    }

    #{$start + 400} {
      stroke-dashoffset: 0;
    }
  }

  #skills {
    -st-animation-name: a_skills;
  }

  @-st-keyframes a_skills {

    #{$start + 1140} {
      transform: scale(1) translate(0px, 100px);
    }

    #{$start + 1180} {
      transform: scale(1.6) translate(-120px, 100px);
    }

    #{$start + 1430} {
      opacity: 1;
    }

    #{$start + 1480} {
      opacity: 0;
    }
  }

  #skillpath {
    -st-animation-name: a_skillpath;
  }

  @-st-keyframes a_skillpath {

    #{$start - 50} {
      opacity: 0;
    }

    #{$start} {
      opacity: 1;
    }
  }

  #skillnames {
    -st-animation-name: a_skillnames;
  }

  @-st-keyframes a_skillnames {

    #{$start } {
      opacity: 0;
    }

    #{$start + 100} {
      opacity: 1;
    }
  }

  #invincible {
    -st-animation-name: a_invincible;
  }

  @-st-keyframes a_invincible {

    #{$start + 1080} {
      opacity: 0;
    }

    #{$start + 1120} {
      opacity: 1;
    }
  }

  #hellobye {
    opacity: 0;
    -st-animation-name: a_hellobye;
  }

  @-st-keyframes a_hellobye {

    #{$start + 1600} {
      opacity: 0;
    }

    #{$start + 1650} {
      opacity: 1;
    }
  }

  #endtitles {
    -st-animation-name: a_endtitles;
  }

  @-st-keyframes a_endtitles {

    #{$start + 1500} {
      transform: translate(0, 800px);
    }

    #{$start + 2300} {
      transform: translate(0, -860px);
    }
  }

  #bgright {
    -st-animation-name: a_bgright;
  }

  #me {
    transform: scale(0.2) ;
  }

  @-st-keyframes a_bgright {

    #{$start + 1450} {
      transform: translate(800px, 0);
      opacity: 0;
    }

    #{$start + 1500} {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  .clip-svg {
    clip-path: url('#myClip');
  }
}
