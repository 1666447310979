article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
}

figure {
  margin: 0;
}

a {
  &:link {
    color: #3498db;
    text-decoration: none;
    font-weight: 400;
  }

  &:visited {
    color: #3498db;
  }

  &:hover {
    color: #151d29;
    outline: 0;
  }

  &:focus {
    color: #151d29;
    outline: thin dotted;
  }

  &:active {
    color: #151d29;
    outline: 0;
  }
}

h1 {
  margin: 0.67em 0;
  font-size: 2em;
  font-weight: 100;
}

img {
  border: 0;
  max-width: 100%;
}

[hidden] {
  display: none;
}
