
$scene_01_begin: 0;
$scene_01_duration: 900;
$scene_01_end: $scene_01_begin + $scene_01_duration;

$scene_02_begin: $scene_01_end;
$scene_02_duration: 1100;
$scene_02_end: $scene_02_begin + $scene_02_duration;

$scene_03_begin: $scene_02_end;
$scene_03_duration: 1100;
$scene_03_end: $scene_03_begin + $scene_03_duration;

$scene_04_begin: $scene_03_end;
$scene_04_duration: 1000;
$scene_04_end: $scene_04_begin + $scene_04_duration;

$scene_05_begin: $scene_04_end;
$scene_05_duration: 2100;
$scene_05_end: $scene_05_begin + $scene_05_duration;

$scene_06_begin: $scene_05_end;
$scene_06_duration: 2500;
$scene_06_end: $scene_06_begin + $scene_06_duration;

@import 'menu/animation';
@import 'scene1/animation';
@import 'scene2/animation';
@import 'scene3/animation';
@import 'scene4/animation';
@import 'scene5/animation';
@import 'scene6/animation';

#scene1 { -st-animation-name: scene1; }

@-st-keyframes scene1 {
  #{$scene_01_begin} {
    top: 0%;
  }
  #{$scene_01_end - 500} {
    top: 0%;
  }
  #{$scene_01_end} {
    top: -100%;
  }
}

#scene2 { -st-animation-name: scene2; }

@-st-keyframes scene2 {
  #{$scene_02_begin - 500} {
    top: 100%;
  }
  #{$scene_02_begin} {
    top: 0%;
  }
  #{$scene_02_end - 500} {
    top: 0%;
  }
  #{$scene_02_end} {
    top: -100%;
  }
}

#scene3 { -st-animation-name: scene3; }

@-st-keyframes scene3 {
  #{$scene_03_begin - 500} {
    top: 100%;
  }
  #{$scene_03_begin} {
    top: 0%;
  }
  #{$scene_03_end - 500} {
    top: 0%;
  }
  #{$scene_03_end} {
    top: -100%;
  }
}

#scene4 { -st-animation-name: scene4; }

@-st-keyframes scene4 {
  #{$scene_04_begin - 500} {
    top: 100%;
  }
  #{$scene_04_begin} {
    top: 0%;
  }
  #{$scene_04_end - 500} {
    top: 0%;
  }
  #{$scene_04_end} {
    top: -100%;
  }
}

#scene5 { -st-animation-name: scene5; }

@-st-keyframes scene5 {

  #{$scene_05_begin - 500} {
    top: 100%;
  }
  #{$scene_05_begin} {
    top: 0%;
  }
  #{$scene_05_end - 500} {
    top: 0%;
  }
  #{$scene_05_end} {
    top: -100%;
  }
}

#scene6 { -st-animation-name: scene6; }

@-st-keyframes scene6 {

  #{$scene_06_begin - 500} {
    top: 100%;
  }
  #{$scene_06_begin} {
    top: 0%;
  }
  #{$scene_06_end - 500} {
    top: 0%;
  }
}
