@import 'spinner';

body[data-display="divertissement"] {
  #resume {
    display: none;
  }
}

#bottomContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

[role="placeholder"] {
  position: absolute;
  top: 0;
  width: 100%;
}

[role="site"] {
  opacity: 0;
  height: 100%;

  &[uiState="show"] {
    opacity: 1;
  }

  &[uiState="hidden"] {
    display: none;
  }
}

#videoPlayer {
  background-color: black;
  margin: 0 auto;
  width: 1px;
  z-index: 110;
  height: 1px;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
}

#menu {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 103;
}

#vignette {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 200px rgba(0, 0, 0, 0.3) inset;
  z-index: 109;
  pointer-events: none;
  opacity: 1;
  transition: 500ms opacity;

  &[uiState="hidden"] {
    opacity: 0;
  }
}

.scrollable {
  position: fixed;
  z-index: 100;
}

.scrollable .scrollable {
  position: absolute;
}

.scrollable .scrollable .scrollable {
  position: static;
}
