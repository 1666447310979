#scene4 {
  $start3: $scene_04_begin - 200;

  #wire4 {
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-dasharray: 6000;
    stroke-dashoffset: 0;
    -st-animation-name: wire4;
  }

  @-st-keyframes wire4 {
    #{$start3 - 205} {
      stroke-dashoffset: 6000;
    }
    #{$start3 + 1350} {
      stroke-dashoffset: 0;
    }
  }

  #interested {
    -st-animation-name: interested;
  }

  @-st-keyframes interested {
    #{$start3 + 350} {
      opacity: 0;
    }
    #{$start3 + 400} {
      opacity: 1;
    }
  }

  #lalineag {
    -st-animation-name: lalineag;
  }

  @-st-keyframes lalineag {
    #{$start3 + 300} {
      opacity: 0;
    }
    #{$start3 + 350} {
      opacity: 1;
    }
  }

  #list {
    -st-animation-name: list;
  }

  @-st-keyframes list {
    #{$start3 + 100} {
      transform: translate(0px, 0px) scale(1);
    }
    #{$start3 + 150} {
      transform: translate(-200px, -230px) scale(2);
    }
  }

  #listshadow {
    -st-animation-name: listshadow;
  }

  @-st-keyframes listshadow {
    #{$start3 + 100} {
      transform: translate(0px, 0px) scale(1);
      opacity: 1;
    }
    #{$start3 + 120} {
      transform: translate(-60px, -3px) scale(1.1);
      opacity: 0;
    }
  }

  #list2 {
    -st-animation-name: list2;
  }

  @-st-keyframes list2 {
    #{$start3 + 300} {
      transform: translate(0px, 0px) scale(1);
    }
    #{$start3 + 350} {
      transform: translate(-1000px, -900px) scale(2.5);
    }
  }

  #list2shadow {
    -st-animation-name: list2shadow;
  }

  @-st-keyframes list2shadow {
    #{$start3 + 300} {
      transform: translate(0px, 0px) scale(1);
      opacity: 1;
    }
    #{$start3 + 320} {
      transform: translate(-60px, -3px) scale(1.1);
      opacity: 0;
    }
  }

  #book1 {
    cursor: pointer;
    -st-animation-name: book1;
  }

  @-st-keyframes book1 {
    #{$start3 - 50} {
      opacity: 0;
      transform: translate(-20px, -20px);
    }
    #{$start3} {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  #book3 {
    cursor: pointer;
    -st-animation-name: book3;
  }

  @-st-keyframes book3 {
    #{$start3 - 100} {
      opacity: 0;
      transform: translate(-20px, -20px) scale(0.3) rotate(12);
    }
    #{$start3 - 50} {
      opacity: 1;
      transform: translate(0px, 0px) scale(0.3) rotate(12);
    }
  }

  #notebook1 {
    -st-animation-name: notebook1;
  }

  @-st-keyframes notebook1 {
    #{$start3} {
      opacity: 0;
      transform: translate(30, -210) rotate(-3);
    }
    #{$start3 + 50} {
      opacity: 1;
      transform: translate(50, -190.8734) rotate(-3);
    }
  }

  #book2 {
    cursor: pointer;
    -st-animation-name: book2;
  }

  @-st-keyframes book2 {
    #{$start3 + 50} {
      opacity: 0;
      transform: translate(-20px, -20px);
    }
    #{$start3 + 100} {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  #book4 {
    -st-animation-name: book4;
  }

  @-st-keyframes book4 {
    #{$start3 + 100} {
      opacity: 0;
      transform: translate(1000px, 800px) rotate(-6.4) scale(0.49);
    }
    #{$start3 + 150} {
      opacity: 1;
      transform: translate(1000px, 600px) rotate(-6.4) scale(0.49);
    }
  }

  #book5 {
    -st-animation-name: book5;
  }

  #drawing {
    -st-animation-name: drawing;
  }

  @-st-keyframes book5 {
    #{$start3 + 250} {
      opacity: 0;
      transform: translate(-20px, -20px);
    }
    #{$start3 + 300} {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  @-st-keyframes drawing {
    #{$start3 + 200} {
      opacity: 0;
      transform: translate(450, 0) scale(0.4);
    }
    #{$start3 + 250} {
      opacity: 1;
      transform: translate(500, 260) scale(0.4);
    }
  }
}
