$start: $scene_05_begin;

#videoPlayer {
  -st-animation-name: a_videoplayer;
}

@-st-keyframes a_videoplayer {
  #{$start + 1350} {
    opacity: 0;
    display: none;
  }

  #{$start + 1370} {
    opacity: 1;
    display: inline;
  }

  #{$start + 1590} {
    opacity: 1;
    display: inline;
  }

  #{$start + 1610} {
    opacity: 0;
    display: none;
  }
}

#scene5 {
  #wire5 {
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-dasharray: 6000;
    stroke-dashoffset: 0;
    -st-animation-name: wire5;
  }

  @-st-keyframes wire5 {
    #{$start - 385} {
      stroke-dashoffset: 6000;
    }

    #{$start + 1000} {
      stroke-dashoffset: 0;
    }
  }

  #bg {
    -st-animation-name: bg;
  }

  @-st-keyframes bg {
    #{$start} {
      opacity: 0;
    }

    #{$start + 160} {
      opacity: 0;
    }

    #{$start + 200} {
      opacity: 1;
    }

    #{$start + 350} {
      opacity: 1;
    }

    #{$start + 390} {
      opacity: 0;
    }
  }

  #scene {
    -st-animation-name: scene4r;
  }

  @-st-keyframes scene4r {
    #{$start + 1150} {
      transform: rotate(0deg) translate(0px, 0px) scale(1);
    }

    #{$start + 1350} {
      transform: rotate(90deg) translate(-6930px, -530px) scale(8);
    }
  }

  #fg {
    -st-animation-name: fgg;
  }

  @-st-keyframes fgg {
    #{$start} {
      opacity: 0;
    }

    #{$start + 160} {
      opacity: 0;
    }

    #{$start + 200} {
      opacity: 0.4;
    }

    #{$start + 350} {
      opacity: 0.4;
    }

    #{$start + 390} {
      opacity: 0;
    }
  }

  #titles {
    #title1 {
      -st-animation-name: title1;
    }

    #title2 {
      -st-animation-name: title2;
    }

    #title3 {
      -st-animation-name: title3;
    }
  }

  @-st-keyframes title1 {
    #{$start} {
      opacity: 0;
    }

    #{$start + 100} {
      opacity: 1;
    }

    #{$start + 300} {
      opacity: 1;
    }

    #{$start + 350} {
      opacity: 0;
    }
  }

  @-st-keyframes title2 {
    #{$start} {
      opacity: 0;
    }

    #{$start + 400} {
      opacity: 0;
    }

    #{$start + 450} {
      opacity: 1;
    }

    #{$start + 900} {
      opacity: 1;
    }

    #{$start + 950} {
      opacity: 0;
    }
  }

  @-st-keyframes title3 {
    #{$start + 940} {
      opacity: 0;
    }

    #{$start + 980} {
      opacity: 1;
    }
  }

  #desks {
    -st-animation-name: desks;

    #employee {
      -st-animation-name: employee;
    }

    #entrepeneur {
      -st-animation-name: entrepeneur;
    }

    #freelance {
      -st-animation-name: freelance;
    }
  }

  @-st-keyframes desks {
    #{$start + 400} {
      transform: translate(0px, 0px) scale(1);
    }

    #{$start + 450} {
      transform: translate(300px, 0px) scale(0.6);
    }

    #{$start + 900} {
      transform: translate(300px, 0px) scale(0.6);
    }

    #{$start + 950} {
      transform: translate(0px, 0px) scale(1);
    }
  }

  #clients {
    #vneuron {
      -st-animation-name: vneuron;
      cursor: pointer;
    }

    #makerlab {
      -st-animation-name: makerlab;
      cursor: pointer;
    }

    #sofia {
      -st-animation-name: sofia;
      cursor: pointer;
    }

    #eonics {
      -st-animation-name: eonics;
      cursor: pointer;
    }

    #rabobank {
      -st-animation-name: rabobank;
      cursor: pointer;
    }

    #ah {
      -st-animation-name: ah;
      cursor: pointer;
    }

    #googlejam {
      -st-animation-name: googlejam;
      cursor: pointer;
    }

    #sfaxcpc {
      -st-animation-name: sfaxcpc;
      cursor: pointer;
    }
  }

  @-st-keyframes sofia {
    #{$start + 100} {
      transform: translate(-3000px, -8000px) scale(16);
      opacity: 0;
    }

    #{$start + 150} {
      transform: translate(0px, 0px) scale(1);
      opacity: 1;
    }
  }

  @-st-keyframes vneuron {
    #{$start + 140} {
      transform: translate(0px, 1000px);
      opacity: 0;
    }

    #{$start + 210} {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }

  @-st-keyframes makerlab {
    #{$start + 260} {
      transform: translate(-3000px, -8000px) scale(16);
      opacity: 0;
    }

    #{$start + 310} {
      transform: translate(0px, 0px) scale(1);
      opacity: 1;
    }
  }

  @-st-keyframes eonics {
    #{$start + 350} {
      transform: translate(-2600px, -8000px) scale(16);
      opacity: 0;
    }

    #{$start + 450} {
      transform: translate(-13.6981, 100.055) scale(1);
      opacity: 1;
    }
  }

  @-st-keyframes rabobank {
    #{$start + 450} {
      transform: translate(-2400px, -8000px) scale(16);
      opacity: 0;
    }

    #{$start + 550} {
      transform: translate(30.72, 150.055) scale(1);
      opacity: 1;
    }
  }

  @-st-keyframes ah {
    #{$start + 550} {
      transform: translate(-2200px, -5000px) scale(16);
      opacity: 0;
    }

    #{$start + 650} {
      transform: translate(80.72, 200.055) scale(1);
      opacity: 1;
    }
  }

  @-st-keyframes googlejam {
    #{$start + 120} {
      transform: translate(-2600px, -8000px) scale(1) rotate(7.2);
      opacity: 0;
    }

    #{$start + 170} {
      transform: translate(636.72, 204.062) scale(0.4) rotate(7.2);
      opacity: 1;
    }
  }

  @-st-keyframes sfaxcpc {
    #{$start + 200} {
      transform: translate(-4700px, -8000px) scale(10);
      opacity: 0;
    }

    #{$start + 300} {
      transform: translate(-15.6153, -175.672) scale(1);
      opacity: 1;
    }
  }

  #mouse04 {
    -st-animation-name: mouse04;
  }

  $mousestart: $start - 200;

  @-st-keyframes mouse04 {
    #{$mousestart} {
      transform: rotate(1deg);
    }

    #{$mousestart + 50} {
      transform: rotate(-1deg);
    }

    #{$mousestart + 100} {
      transform: rotate(2deg);
    }

    #{$mousestart + 150} {
      transform: rotate(-1deg);
    }

    #{$mousestart + 200} {
      transform: rotate(0deg);
    }

    #{$mousestart + 250} {
      transform: rotate(0deg);
    }

    #{$mousestart + 300} {
      transform: rotate(-1deg);
    }

    #{$mousestart + 350} {
      transform: rotate(-2deg);
    }

    #{$mousestart + 500} {
      transform: rotate(-2deg);
    }

    #{$mousestart + 550} {
      transform: rotate(1deg);
    }

    #{$mousestart + 750} {
      transform: rotate(1deg);
    }

    #{$mousestart + 800} {
      transform: rotate(2deg);
    }

    #{$mousestart + 850} {
      transform: rotate(1deg);
    }

    #{$mousestart + 950} {
      transform: rotate(0deg);
    }

    #{$mousestart + 1050} {
      transform: rotate(1deg);
    }

    #{$mousestart + 1250} {
      transform: rotate(2deg);
    }

    #{$mousestart + 1450} {
      transform: rotate(-1deg);
    }
  }

  #mouse04-1 {
    -st-animation-name: mouse04-1;
  }

  @-st-keyframes mouse04-1 {
    #{$mousestart} {
      transform: rotate(1deg);
    }

    #{$mousestart + 50} {
      transform: rotate(-1deg);
    }

    #{$mousestart + 100} {
      transform: rotate(2deg);
    }

    #{$mousestart + 150} {
      transform: rotate(-1deg);
    }

    #{$mousestart + 200} {
      transform: rotate(0deg);
    }

    #{$mousestart + 250} {
      transform: rotate(0deg);
    }

    #{$mousestart + 300} {
      transform: rotate(-1deg);
    }

    #{$mousestart + 350} {
      transform: rotate(-2deg);
    }

    #{$mousestart + 500} {
      transform: rotate(-2deg);
    }

    #{$mousestart + 550} {
      transform: rotate(1deg);
    }

    #{$mousestart + 750} {
      transform: rotate(1deg);
    }

    #{$mousestart + 800} {
      transform: rotate(2deg);
    }

    #{$mousestart + 850} {
      transform: rotate(1deg);
    }

    #{$mousestart + 950} {
      transform: rotate(0deg);
    }

    #{$mousestart + 1050} {
      transform: rotate(1deg);
    }

    #{$mousestart + 1250} {
      transform: rotate(2deg);
    }

    #{$mousestart + 1450} {
      transform: rotate(-1deg);
    }
  }

  #mouse04-2 {
    -st-animation-name: mouse04-2;
  }

  @-st-keyframes mouse04-2 {
    #{$mousestart} {
      transform: rotate(1deg);
    }

    #{$mousestart + 50} {
      transform: rotate(-1deg);
    }

    #{$mousestart + 100} {
      transform: rotate(2deg);
    }

    #{$mousestart + 150} {
      transform: rotate(-1deg);
    }

    #{$mousestart + 200} {
      transform: rotate(0deg);
    }

    #{$mousestart + 250} {
      transform: rotate(0deg);
    }

    #{$mousestart + 300} {
      transform: rotate(-1deg);
    }

    #{$mousestart + 350} {
      transform: rotate(-2deg);
    }

    #{$mousestart + 500} {
      transform: rotate(-2deg);
    }

    #{$mousestart + 550} {
      transform: rotate(1deg);
    }

    #{$mousestart + 750} {
      transform: rotate(1deg);
    }

    #{$mousestart + 800} {
      transform: rotate(2deg);
    }

    #{$mousestart + 850} {
      transform: rotate(1deg);
    }

    #{$mousestart + 950} {
      transform: rotate(0deg);
    }

    #{$mousestart + 1050} {
      transform: rotate(1deg);
    }

    #{$mousestart + 1250} {
      transform: rotate(2deg);
    }

    #{$mousestart + 1450} {
      transform: rotate(-1deg);
    }
  }

  @-st-keyframes employee {
    #{$start + 400} {
      opacity: 0;
    }

    #{$start + 450} {
      opacity: 1;
    }

    #{$start + 900} {
      opacity: 1;
    }

    #{$start + 950} {
      opacity: 0;
    }
  }

  @-st-keyframes entrepeneur {
    #{$start + 900} {
      opacity: 0;
    }

    #{$start + 950} {
      opacity: 1;
    }
  }

  @-st-keyframes freelance {
    #{$start + 400} {
      opacity: 1;
    }

    #{$start + 450} {
      opacity: 0;
    }
  }

  #deskleft {
    -st-animation-name: deskleft;
  }

  @-st-keyframes deskleft {
    #{$start + 400} {
      opacity: 0;
      transform: translate(-500px, 0px);
    }

    #{$start + 450} {
      opacity: 1;
      transform: translate(0px, 0px);
    }

    #{$start + 900} {
      opacity: 1;
      transform: translate(0px, 0px);
    }

    #{$start + 950} {
      opacity: 0;
      transform: translate(-500px, 0px);
    }
  }

  #deskright {
    -st-animation-name: deskright;
  }

  @-st-keyframes deskright {
    #{$start + 400} {
      opacity: 0;
      transform: translate(500px, 0px);
    }

    #{$start + 450} {
      opacity: 1;
      transform: translate(0px, 0px);
    }

    #{$start + 900} {
      opacity: 1;
      transform: translate(0px, 0px);
    }

    #{$start + 950} {
      opacity: 0;
      transform: translate(500px, 0px);
    }
  }
}